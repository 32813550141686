<template>
  <div class="grid grid-flow-col  lg:grid-cols-3  place-items-center py-20 gap-y-2 px-2">
    
      <div>
        <!-- <div class="text-lg text-left text-StemBlue" for="age">Mosha</div> -->
        <select
        name="age"
          class="
            cursor-pointer
            w-max
            lg:w-72
            border-2 border-StemYellow
            px-2
            bg-transparent
            StemTextSecond text-lg
            font-normal
            h-14
          "
          v-model="selectedAge"
        >
          <!-- <option value="0" selected>All</option> -->
          <option v-for="item in ageGroups" :key="`age_${item.id}`" :value="item.id">{{ item.text }}</option>
        </select>
      </div>
      <div>
         <!-- <div class="text-lg text-left text-StemBlue" for="category">Kategoria</div> -->
        <select
        name="category"
          class="
            class=
            cursor-pointer
            w-max
            lg:w-72
            border-2 border-StemYellow
            px-2
            bg-transparent
            StemTextSecond text-lg
            font-normal
            h-14
          "
          v-model="selectedCategory"
        >
          
          <!-- <option value="0" selected>All</option> -->
          <option v-for="item in categories" :key="`category_${item.id}`" :value="item.id">{{ item.name }}</option>
        </select>
      </div>
    

  </div>
  <div class="AboutDeco3">
    <div
      class="
        grid md:grid-cols-2 lg:grid-cols-3  grid-flow-row
        place-items-center
        md:gap-y-24
        gap-y-3
        AboutDeco4
      "
    >
      <CoursesCards v-for="i in filteredItems" :key="i" :data="i"> </CoursesCards>
      
    </div>
  </div>
</template>

<script>
import CoursesCards from "../base/coursescards.vue";
import courses from "@/data/courses";
import categories from "@/data/categories";
import ageGroups from "@/data/ageGroups";
export default {
  components: { CoursesCards },
  data() {
    return {
      items: courses.data,
      categories: categories.data,
      ageGroups: ageGroups.data,
      selectedCategory: 0,
      selectedAge: 0,
    }
  },
  computed: {
    filteredItems() {
      return this.items.filter(i => 
        ((+i.categoryId === +this.selectedCategory) || +this.selectedCategory === 0) &&
        ((+i.ageGroupId === +this.selectedAge) || +this.selectedAge === 0)
      )
    }
  },
  mounted() {
    if (this.$route.params.category) {
      this.selectedCategory = this.$route.params.category
    }
  }
};
</script>

<style>
.AboutDeco3 {
  background-image: url("./../../assets/Svg/AboutDeco3.svg");
  background-repeat: no-repeat;
  background-position-x: 10%;
  background-position-y: 50%;
}
.AboutDeco4 {
  background-image: url("./../../assets/Svg/AboutDeco4.svg");
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: 50%;
  z-index: -1;
}
</style>