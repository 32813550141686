<template>
  <div class="bg-white">
  <NavBarNew></NavBarNew>
  <CoursesHero></CoursesHero>
  <CoursesCards1></CoursesCards1>
  <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBarNew from "@/components/HomeScreen/NavBarNew.vue";
import CoursesHero from "@/components/CoursesScreen/CoursesHero.vue";
import CoursesCards1 from "@/components/CoursesScreen/Courses_Cards.vue";
import Footer from "@/components/HomeScreen/Footer.vue";
//import Header from '@/components/HomeScreen/header.vue'

export default {
  name: "About Us",
  components: {
    NavBarNew,
    CoursesHero,
    CoursesCards1,
    Footer,
  },
};
</script>