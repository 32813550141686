<template>
  <div class="pt-48 md:px-24 px-3 AboutDeco1">
    <div class="AboutDeco2">
      <div class="grid md:grid-flow-col grid-flow-row">
        <div class="border-StemPurple border-l-2 pl-4">
          <h1 class="text-StemBlue font-bold text-4xl pr-4 text-left">
            Kurset e STEMLab
          </h1>
        </div>
        <div class=""> 
          <p
            class="
              text-StemTextSecond
              font-normal
              text-sm
              pr-4
              leading-loose
              text-left
              w-80
            "
          >
            Nëpërmjet STEM metodologjisë, mësuesit zhvillojnë nxënës të suksesshëm të cilët janë të gatshëm për të mësuar thellësisht rreth STEM lëndëve, duke kthyer natyrën kurioze të çdo fëmije në zgjidhje krijuese.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
</script>

<style>
.AboutDeco1 {
  background-image: url("./../../assets/Svg/AboutDeco1.svg");
  background-repeat: no-repeat;
  background-position-x: 75%;
  background-position-y: 60%;
}
.AboutDeco2 {
  background-image: url("./../../assets/Svg/AboutDeco2.svg");
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: bottom;
}
</style>